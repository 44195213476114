import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | BankRoll Swap',
  defaultTitle: 'BankRoll Swap',
  description:
    'Cheaper and faster than Uniswap? Discover BankRoll Swap, the leading DEX on BankRoll Swap with the best farms in DeFi and a lottery for RAZEN.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@BankRoll Swap',
    site: '@BankRoll Swap',
  },
  openGraph: {
    title: 'BankRoll Swap - A next evolution DeFi exchange on BankRoll Swap',
    description:
      'The most popular AMM on RAZEN by user count! Earn RAZEN through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by BankRoll Swap, NFTs, and more, on a platform you can trust.',
    images: [{ url: 'https://bankroll.pro.finance/web/og/hero.jpg' }],
  },
}
