import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.RZN] // edit
export const SUPPORT_FARMS = [ChainId.RZN]

export const SUPPORT_ZAP = [ChainId.RZN]
export const SUPPORT_STAKING = [ChainId.BSC_TESTNET]

export const CHART_SUPPORT_CHAIN_IDS = [ChainId.RZN]
export const ACCESS_TOKEN_SUPPORT_CHAIN_IDS = [ChainId.RZN]
export const STABLE_SUPPORT_CHAIN_IDS = [ChainId.RZN]
